import React from "react";
import Login from "../component/login/login";

function LoginPage() {
  return (
    <div>
      <Login />
    </div>
  );
}

export default LoginPage;
